import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    impersonationProfileId: null,
    getConfig() {
        //console.log('TOKEN', store.getters.jwtToken);
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                "x-impersonation-profile-id": this.impersonationProfileId || '',
            }
        };
    },
    get(category,periodicity,relativity,activityType, page) {
      return services.http.get(`/v1.0/rankings/${category}/${periodicity}/${relativity}/${activityType}?page=${page||''}`, this.getConfig());
    },
    getBreakdown(category,subId,periodicity,relativity,activityType, page) {
        return services.http.get(`/v1.0/rankings/breakdown/${category}/${subId}/${periodicity}/${relativity}/${activityType}?page=${page||''}`, this.getConfig());
    },
    

}