<template>
  <div v-if="profile">
    profile if {{profile.id}} = {{profile.name}}
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileView",
  props: {
  },
  data() {
    return {
      tenant: tenant,
      profile: null,
    };
  },
  mounted() {
  },
  methods: {
    async loadById(id) {
      this.profile = (await profileService.publicProfile(id)).data;
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style scoped>
  .account-button {}
  .account-button::after { content: '\f078'; font-family: 'Font Awesome 5 Pro'; margin-left:-7px; margin-right: 2px; }
  .v-application--is-rtl .account-button::after { margin-right:-7px; margin-left: 2px; }
</style>