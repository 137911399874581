<template>
  <div>
    <v-card>
      <v-card-title class="display-2">
        <v-col class="pa-0 pl-4 grow">Global Rankings</v-col>
      </v-card-title>
      <v-row class="mx-3">
        <v-col cols="12" md="8">
          <h2>Leaderboard</h2>
          <!-- <RankingGrid :tile="tile" :activityType="$route.params.type" :rankingKey="$route.query.ranking" /> -->
          <v-alert v-if="parent" type="info" outlined dismissible close-icon="fa-times-circle" @input="parent=null;updateRankings()">
            Showing rankings within <strong>{{ parent.name }}</strong>.
          </v-alert>
          <v-simple-table v-if="rankings && rankings.length > 0" fixed-header >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left"><v-chip outlined color="accent">#</v-chip></th>
                  <th class="text-left">&nbsp;</th>
                  <th class="text-left">Name</th>
                  <th v-if="false" class="text-left">Points</th>
                  <th class="text-left">Distance</th>
                  <th class="text-left">Difference</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in rankings" :key="idx"  @click="openDetails(item)">
                  <td>
                    <v-chip color="accent">
                      <span v-if="item.pos == getExpectedPosition(idx)">{{ item.pos}}</span>
                      <v-icon v-else x-small>fa-spin fa-spinner</v-icon>
                    </v-chip>
                  </td>
                  <td>
                    <v-avatar v-if="item.img" size="32"><img :src="item.img+(item.img.indexOf('?')>0?'&':'?')+'w=64&h=64'" onerror="this.style.display='none';"/></v-avatar>
                  </td>
                  <td>
                    <span>{{ item.name }} </span>
                    <v-btn v-if="item.bd" icon color="primary" @click="showBreakdown(item)">
                      <v-icon small>fa-chevron-right</v-icon>
                    </v-btn>
                  </td>
                  <td><v-chip outlined color="green" class="">{{ item.s * 1000 | distance('METRIC') }}</v-chip></td>
                  <td>
                    <span v-if="item.diff && item.diff > 0" class="text-muted">
                      <v-icon style="width:30px;">fa-caret-up</v-icon> +{{ item.diff }}
                    </span>
                    <span v-else-if="item.diff && item.diff === 0" class="text-muted">
                      <!-- <v-icon small>fa-sort-circle</v-icon> - -->
                      <v-icon style="width:30px;">fa-caret-left</v-icon> Same
                    </span>
                    <span v-else-if="item.diff && item.diff < 0" class="text-muted">
                      <v-icon style="width:30px;">fa-caret-down</v-icon> {{ item.diff }}
                    </span>
                    <!-- <span v-else class="text-muted">
                      <v-icon style="width:30px;">fa-caret-right</v-icon> New
                    </span> -->
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>          
          <v-pagination 
            v-if="meta && meta.total_pages > 1" 
            v-model="resultPage" 
            circle 
            :length="meta.total_pages" 
            @input="loadResults"
            />
          <p v-if="rankings && rankings.length == 0" class="text-italic py-4" >
            No rankings available for the selected period and activity type.
          </p>  
        </v-col>
        <v-col cols="12" md="4">
          <v-col cols="12" class="py-0">
            <h4>Category</h4>
            <v-btn-toggle v-if="tenant.rankedGroupCategory" v-model="selectedCategory" class="pa-0" style="width: 100%;" color="primary" @change="updateRankings">
              <v-btn value="OVERALL" style="width:50%;">Individuals</v-btn>
              <v-btn :value="tenant.rankedGroupCategory" style="width:50%;">Teams</v-btn>
            </v-btn-toggle>
            <v-text-field 
              v-model="nameFilter" 
              solo
              class="mt-4"
              hide-details
              >
              <template v-slot:label>
                <span>
                  <v-icon icon="fa-search" /> Filter by name
                </span>
              </template>              
            </v-text-field>
            <div v-if="tenant.id != 'wmm'">
            <h4 class="mt-4">Activity Type</h4>
            <v-btn-toggle v-model="selectedActivityType" class="pa-0" color="primary" @change="updateRankings">
              <v-btn value="RUNNING"><v-icon title="Running">{{$helpers.getActivityIcon('RUNNING')}}</v-icon></v-btn>
              <v-btn value="CYCLING"><v-icon title="Cycling">{{$helpers.getActivityIcon('CYCLING')}}</v-icon></v-btn>
              <v-btn value="WALKING"><v-icon title="Walking / Hiking">{{$helpers.getActivityIcon('WALKING')}}</v-icon></v-btn>
            </v-btn-toggle>
            </div>
            <h4 class="mt-4">Period</h4>
            <v-btn-toggle v-model="selectedPeriod" class="pa-0 mb-4" style="width: 100%;" color="primary" @change="updateRankings">
              <v-btn value="CURRENT" class="" style="width:50%;">Current</v-btn>
              <v-btn value="PREVIOUS" class="" style="width:50%;">Previous</v-btn>
            </v-btn-toggle>
            <v-btn-toggle v-model="selectedGrouping" class="pa-0" style="width: 100%;" color="primary" @change="updateRankings">
              <v-btn value="YEAR" class="" style="width:33%;">Year</v-btn>
              <v-btn value="MONTH" class="" style="width:34%;">Month</v-btn>
              <v-btn value="WEEK" class="" style="width:33%;">Week</v-btn>
            </v-btn-toggle>
          </v-col>

        </v-col>
      </v-row>
      <br/>
    </v-card>
    <v-dialog v-model="showProfileDialog" max-width="500">
      <v-card>
        <ProfileView ref="profileView" />
      </v-card>
    </v-dialog>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import profileService from "@/services/profileService";
import rankingsService from "@/services/rankingsService";
import ProfileView from '@/components/profile/ProfileView.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Rankings",
  components: {
    ProfileView,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      profile: null,
      meta: null,
      rankings: null,
      parent: null,
      resultPage: 1,
      selectedCategory: 'OVERALL',
      selectedActivityType: 'RUNNING',
      selectedGrouping: 'MONTH',
      selectedPeriod: 'CURRENT',
      showProfileDialog: false,
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.updateRankings();
    },

    async showBreakdown(item) {
      this.parent = item;

      var result = await rankingsService.getBreakdown(this.selectedCategory.toLowerCase(), item.bd_id, this.selectedGrouping.toLowerCase(),this.selectedPeriod.toLowerCase(),this.selectedActivityType.toLowerCase(), this.resultPage);
      this.meta = result.data.meta;
      this.rankings = result.data.data;
      //console.log('/// rankings', this.rankings);
    },

    async updateRankings() {
      this.parent = null;
      this.resultPage = 1;
      await this.loadResults();
    },
    async loadResults() {
      var result = await rankingsService.get(this.selectedCategory.toLowerCase(), this.selectedGrouping.toLowerCase(),this.selectedPeriod.toLowerCase(),this.selectedActivityType.toLowerCase(), this.resultPage);
      this.meta = result.data.meta;
      this.rankings = result.data.data;
      //console.log('/// rankings', this.rankings);
    },

    async openDetails(item) {
      if (item.bd) {
        return;
      }
      if (item.p_id) {
        await this.openProfileDialog(item.p_id);
      }
    },

    async openProfileDialog(profileId) {
      this.showProfileDialog = true;
      await this.$nextTick();
      this.$refs.profileView.loadById(profileId);
    },

    getExpectedPosition(index) {
      return ((this.resultPage-1)*10)+(index+1);
    }

  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    
  },

};
</script>
<style lang="scss">
</style>

